import instance from './instance';
import { Company, TenderCategory } from '../models';
import { PageableResponse } from './responses';
import { PageableParams } from './params';

export interface CreateCompanyData
  extends Omit<Company, 'id' | 'createdAt' | 'updatedAt'> {}

export function createCompany(data: CreateCompanyData): Promise<Company> {
  return instance()
    .post('/companies/create', data)
    .then((response) => response.data);
}

export function getCompanies(
  params: PageableParams & Partial<Company> & { tenderCategory: TenderCategory }
): Promise<PageableResponse<Company>> {
  return instance()
    .get('/companies/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getCompanyById(companyId: string): Promise<Company> {
  return instance()
    .get(`/companies/getById/${companyId}`)
    .then((response) => response.data);
}

export function getCompanyPortfoliosFilesIds(id: string): Promise<string[]> {
  return instance()
    .get(`/companies/${id}/getPortfolioFilesIds`)
    .then((response) => response.data);
}

export function getCompanyByBin(bin: string): Promise<Company> {
  return instance()
    .get(`/companies/getByBin/${bin}`)
    .then((response) => response.data);
}

export function checkCompanyByBin(bin: string): Promise<boolean> {
  return instance()
    .get(`/companies/checkCompanyByBin/${bin}`)
    .then((response) => response.data);
}

export function updateCompany(
  id: string,
  data: Partial<Company>
): Promise<Company> {
  return instance()
    .patch(`/companies/${id}`, data)
    .then((response) => response.data);
}

export function uploadCompanyFiles(
  id: string,
  files: File[]
): Promise<Company> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/companies/${id}/addFiles`, formData)
    .then((response) => response.data);
}

export function uploadCompanyAvatarFiles(
  id: string,
  files: File[]
): Promise<Company> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/companies/${id}/addAvatarFiles`, formData)
    .then((response) => response.data);
}

export function uploadCompanyPortfolioFiles(
  id: string,
  files: File[]
): Promise<string[]> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/companies/${id}/addPortfolioFiles`, formData)
    .then((response) => response.data);
}

export function deleteCompanyFile(
  id: string,
  fileId: string
): Promise<boolean> {
  return instance()
    .delete(`/companies/${id}/files/${fileId}`)
    .then((response) => response.data);
}

export function deleteCompanyPortfolioFile(
  id: string,
  fileId: string
): Promise<boolean> {
  return instance()
    .delete(`/companies/${id}/portfolioFiles/${fileId}`)
    .then((response) => response.data);
}
