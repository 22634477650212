export enum Measure {
  MILLI_MET = 10, // миллиметры
  CENTI_MET = 11, // сантиметры
  METRE = 12, // метры
  KV_MET = 13, // квадратные метры
  K_MET = 14, // километры
  KV_K_MET = 15, // квадратные километры

  KV_MILLI_MET = 16, // квадратные миллиметры
  KV_CENTI_MET = 17, // квадратные сантиметры
  CUB_MILLI_MET = 18, // кубические миллиметры
  CUB_CENTI_MET = 19, // кубические сантиметры
  LITRE = 20, // литры

  METRE_PER_SEC = 21, // метры в секунду
  KM_PER_HOUR = 22, // километры в час

  WATT = 23, // Ватт
  VOLT = 24, // Вольт

  SEC = 30, // секунды
  MIN = 31, // минуты
  HOUR = 32, // час
  DAY = 33, // день

  PACKAGE = 40, // упаковка
  PIECES = 41, // штук

  MILLI_GRAM = 50, // миллиграмм
  GRAM = 51, // грамм
  KILO = 52, // килограммы
  TON = 53, // тонны

  KZT = 70, // тенге
}

export const MeasureLabelsFull: Record<Measure, string> = {
  [Measure.MILLI_MET]: 'миллиметры',
  [Measure.CENTI_MET]: 'сантиметры',
  [Measure.METRE]: 'метры',
  [Measure.KV_MET]: 'квадратные метры',
  [Measure.K_MET]: 'километры',
  [Measure.KV_K_MET]: 'квадратные километры',

  [Measure.KV_MILLI_MET]: 'квадратные миллиметры',
  [Measure.KV_CENTI_MET]: 'квадратные сантиметры',
  [Measure.CUB_MILLI_MET]: 'кубические миллиметры',
  [Measure.CUB_CENTI_MET]: 'кубические сантиметры',
  [Measure.LITRE]: 'литры',

  [Measure.METRE_PER_SEC]: 'метры в секунду',
  [Measure.KM_PER_HOUR]: 'километры в час',

  [Measure.WATT]: 'Ватт',
  [Measure.VOLT]: 'Вольт',

  [Measure.SEC]: 'секунды',
  [Measure.MIN]: 'минуты',
  [Measure.HOUR]: 'час',
  [Measure.DAY]: 'день',

  [Measure.PACKAGE]: 'упаковка',
  [Measure.PIECES]: 'штук',

  [Measure.MILLI_GRAM]: 'миллиграмм',
  [Measure.GRAM]: 'грамм',
  [Measure.KILO]: 'килограммы',
  [Measure.TON]: 'тонны',

  [Measure.KZT]: 'тенге',
};

export const MeasureLabelsShort: Record<Measure, string> = {
  [Measure.MILLI_MET]: 'мм',
  [Measure.CENTI_MET]: 'см',
  [Measure.METRE]: 'м',
  [Measure.KV_MET]: 'кв.м',
  [Measure.K_MET]: 'км',
  [Measure.KV_K_MET]: 'кв.км',

  [Measure.KV_MILLI_MET]: 'кв.мм',
  [Measure.KV_CENTI_MET]: 'кв.см',
  [Measure.CUB_MILLI_MET]: 'куб.мм',
  [Measure.CUB_CENTI_MET]: 'куб.см',
  [Measure.LITRE]: 'л',

  [Measure.METRE_PER_SEC]: 'м/с',
  [Measure.KM_PER_HOUR]: 'км/ч',

  [Measure.WATT]: 'Вт',
  [Measure.VOLT]: 'В',

  [Measure.SEC]: 'сек',
  [Measure.MIN]: 'мин',
  [Measure.HOUR]: 'ч',
  [Measure.DAY]: 'д',

  [Measure.PACKAGE]: 'уп.',
  [Measure.PIECES]: 'шт.',

  [Measure.MILLI_GRAM]: 'мг',
  [Measure.GRAM]: 'г',
  [Measure.KILO]: 'кг',
  [Measure.TON]: 'т',

  [Measure.KZT]: '₸',
};
