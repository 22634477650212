import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Partner } from '@app/models';
import placeholderImage from '../../pages/Tender/placeholder.png';
import { Info, LoadableImage, StyledOkedListBlock } from '@app/components';

interface Props {
  partner: Partner;
}

const StyledGrid = styled.section`
  display: grid;
  grid-gap: 16px;
`;

const StyledContent = styled.div`
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
`;

const StyledContentGrid = styled.div`
  display: flex;
`;

const StyledImageWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 400px;
  height: 400px;
  margin-right: 64px;
`;

const StyledInfos = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: min-content;
`;

function PartnerInfo(props: Props & PropsWithChildren) {
  const { partner, children } = props;

  return (
    <StyledGrid>
      <StyledContent>
        <StyledContentGrid>
          <StyledImageWrapper>
            <LoadableImage imageIds={[]} placeholder={placeholderImage} />
          </StyledImageWrapper>
          <StyledInfos>
            {!!partner.bin && <Info label="БИН партнера" value={partner.bin} />}
            {!!partner.iin && <Info label="ИИН партнера" value={partner.iin} />}
            <Info
              label="Количество договоров"
              value={partner.docsCount.toString()}
            />
            <Info label="Период сотрудничества" value="-" />
            <Info
              label="Регион"
              value={!!partner.kato ? partner.kato.nameRu : '-'}
            />

            <Info
              label="Категория"
              value={<StyledOkedListBlock list={partner.okeds} />}
            />
          </StyledInfos>
        </StyledContentGrid>
        {children}
      </StyledContent>
    </StyledGrid>
  );
}

export default memo(PartnerInfo);
