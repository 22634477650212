import { Company, Portfolio } from '@app/models';
import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Info from '../Info';
import { theme } from 'styled-tools';
import StarRating from 'components/StarRating/StarRating';
import { calculatePortfolios } from '@app/helpers';
import LoadableImage from '../LodableImage/LoadableImage';
import avatar from '../../assets/images/avatar.png';

interface CardProps extends Partial<Company> {
  onClick?: () => void;
  portfolios: Portfolio[];
  invertBgColorCard?: boolean;
}

const StyledUserCard = styled.div<{ invertBgColorCard: boolean }>`
  display: grid;
  grid-template-columns: 125px 1fr auto;
  /* grid-gap: 24px; */
  background-color: ${({ invertBgColorCard }) =>
    invertBgColorCard ? '#F5F5F5' : theme('color.white')};
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
`;

const StyledImage = styled.div<{ invertBgColorCard: boolean }>`
  width: 125px;
  height: 100%;
  background-color: ${({ invertBgColorCard }) =>
    invertBgColorCard ? '#F5F5F5' : theme('color.white')};
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-gap: 16px;
  padding: 0 0 0 24px;
  box-sizing: border-box;
`;

function CompanyCard(props: CardProps) {
  const {
    name,
    kato,
    rating,
    onClick,
    portfolios,
    avatarFilesIds,
    invertBgColorCard = false,
    okeds,
  } = props;

  const onClickCard = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!onClick) {
        return;
      }

      onClick();
    },
    [onClick]
  );

  const images = useMemo(() => {
    if (avatarFilesIds) {
      return avatarFilesIds.split(',');
    }
    return [];
  }, [avatarFilesIds]);

  const portfolioDate = useMemo(() => {
    return calculatePortfolios(portfolios);
  }, [portfolios]);

  const oked = useMemo(() => {
    if (!okeds || okeds.length === 0) {
      return '-';
    }

    return okeds[0].nameRu;
  }, [okeds]);

  return (
    <StyledUserCard onClick={onClickCard} invertBgColorCard={invertBgColorCard}>
      <StyledImage invertBgColorCard={invertBgColorCard}>
        <LoadableImage imageIds={images} placeholder={avatar} />
      </StyledImage>
      <StyledContent>
        <Info label="Наименование" value={name} small={true} />
        <Info label="Регион" value={kato?.nameRu || '-'} small={true} />
        <Info label="Сфера деятельности" value={oked} small={true} />
        <Info label="На рынке" value={portfolioDate} small={true} />
        <Info
          label="Рейтинг"
          value={<StarRating rating={rating || 0} />}
          small={true}
        />
      </StyledContent>
    </StyledUserCard>
  );
}

export default memo(CompanyCard);
