import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  KatoSelectTrigger,
  Select,
  SelectOption,
} from '@app/components';
import { IconSearch } from '@app/icons';
import {
  Kato,
  Tender,
  TenderTypes,
  Vehicle,
  VehicleStatus,
  VehicleStatusNames,
} from '@app/models';
import './styles.scss';
import { RenderFilterProps } from '@app/templates';
import { BrandSelect } from 'components';
import { useForm } from '../hooks';
import TendersFilter from '../TendersFilter';
import styled from 'styled-components';
import { VehicleTypeSelect } from './components';

interface FormData {
  kato: SelectOption<Kato> | null;
  brand: string;
  type: SelectOption<string> | null;
  status: SelectOption<VehicleStatus> | null;
}

export type VehiclesFilterData<T> = {
  tenderType: TenderTypes;
} & Partial<T>;

interface Props
  extends RenderFilterProps<VehiclesFilterData<Vehicle | Tender>> {}

const StyledVehiclesFilter = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledVehiclesFilterHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const StyledVehiclesFilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

function VehiclesFilter(props: Props) {
  const { onSubmit, pending, okedParentNumber } = props;
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );
  const { values, onChange, setValues } = useForm<FormData>({
    brand: '',
    kato: null,
    type: null,
    status: null,
  });

  const onChangeTenderType = useCallback(
    (item: TenderTypes) => () => {
      setTenderType(item);

      onSubmit({
        tenderType: item,
      });
    },
    [onSubmit]
  );

  const onChangeBrand = useCallback(
    (value: string) => {
      onChange(value, 'brand');
    },
    [onChange]
  );

  const onClickSearch = useCallback(() => {
    const params: VehiclesFilterData<Vehicle> = {
      tenderType,
    };

    if (!!values.kato) {
      params.katoCode = values.kato.value.code;
    }

    if (!!values.brand) {
      params.brand = values.brand;
    }

    if (!!values.type) {
      params.type = values.type.value;
    }

    if (!!values.status) {
      params.status = values.status.value;
    }

    onSubmit(params);
  }, [onSubmit, values, tenderType]);

  const onClickClear = useCallback(() => {
    setValues({
      brand: '',
      kato: null,
      type: null,
      status: null,
    });

    onSubmit({
      tenderType,
    });
  }, [onSubmit, setValues, tenderType]);

  const onSubmitTendersFilter = useCallback(
    (tenderFilterData: Partial<Tender>) => {
      onSubmit({
        tenderType,
        ...tenderFilterData,
      });
    },
    [onSubmit, tenderType]
  );

  const vehicleStatusOptions = useMemo<SelectOption<VehicleStatus>[]>(
    () =>
      [VehicleStatus.FREE, VehicleStatus.BUSY].map((status) => ({
        label: VehicleStatusNames[status],
        value: status,
      })),
    []
  );

  return (
    <StyledVehiclesFilter>
      {/* <StyledVehiclesFilterHeader>
        <div>
          <Checkbox
            checked={tenderType === TenderTypes.REQUEST}
            variant="radio"
            label="Сдать в аренду"
            onChange={onChangeTenderType(TenderTypes.REQUEST)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <Checkbox
            checked={tenderType === TenderTypes.OFFER}
            variant="radio"
            label="Арендовать спецтехнику"
            onChange={onChangeTenderType(TenderTypes.OFFER)}
          />
        </div>
      </StyledVehiclesFilterHeader> */}
      {tenderType === TenderTypes.REQUEST && (
        <TendersFilter
          pending={pending}
          onSubmit={onSubmitTendersFilter}
          okedParentNumber={okedParentNumber}
        />
      )}
      {tenderType === TenderTypes.OFFER && (
        <>
          <StyledGrid>
            <BrandSelect onChange={onChangeBrand} />
            <KatoSelectTrigger
              label="регион"
              value={!!values.kato ? values.kato.value.nameRu : ''}
              placeholder="Выберите регион"
              name="kato"
              onChange={onChange}
            />
            <VehicleTypeSelect
              value={values.type}
              onChange={onChange}
              name="type"
            />
            <Select<VehicleStatus>
              label="Статус"
              defaultLabel="Все"
              options={vehicleStatusOptions}
              value={values.status}
              onChange={onChange}
              name="status"
            />
          </StyledGrid>
          <StyledVehiclesFilterFooter>
            <Button text="Сбросить все" onClick={onClickClear} variant="text" />
            <Button
              icon={IconSearch}
              onClick={onClickSearch}
              text="Найти"
              loading={pending}
            />
          </StyledVehiclesFilterFooter>
        </>
      )}
    </StyledVehiclesFilter>
  );
}

export default memo(VehiclesFilter);
