import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { Company, Tender, TenderCategory, TenderTypes } from '@app/models';
import { TenderCard } from '@app/common';
import { getCompanies, getTenders, PageableParams } from '@app/api';
import { RenderFilterProps, SearchTemplate } from '@app/templates';
import { IconEmpty } from '@app/icons';
import fallbackImage from '../../assets/images/vehicle_fallback.png';
import { ContractorsFilter, ContractorsFilterData } from '@app/forms';
import { CompanyCard } from '@app/components';

interface Props {
  onClick?: (id: string) => void;
  columns?: number;
  title?: string;
  controls?: ReactNode[];
  defaultParams?: Partial<Company | Tender>;
  tenderCategory: TenderCategory;
  okedParentNumber?: number;
}

function ContractorsSearch(props: Props) {
  const {
    onClick,
    columns,
    title,
    controls,
    defaultParams = {},
    tenderCategory,
    okedParentNumber,
  } = props;
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );

  const emptyTitle = useMemo(
    () =>
      tenderType === TenderTypes.REQUEST
        ? 'Вы еще не публиковали заявки в категории «СМР»'
        : 'Список компании пуст',
    [tenderType]
  );

  const renderTenderItem = useCallback(
    (item: Tender | Company, horizontal?: boolean) => {
      if (tenderType === TenderTypes.REQUEST) {
        return (
          <TenderCard
            horizontal={horizontal}
            tender={item as Tender}
            onClick={onClick}
            fallbackImage={fallbackImage}
          />
        );
      }

      return (
        <CompanyCard
          {...(item as Company)}
          onClick={() => {}}
          portfolios={[]}
        />
      );
    },
    [onClick, tenderType]
  );

  const getData = useCallback(
    (
      params: PageableParams,
      filter?: ContractorsFilterData<Tender | Company>
    ) => {
      const { tenderType, ...restFilter } = filter!;

      setTenderType(tenderType);

      if (Number(tenderType) === TenderTypes.OFFER) {
        return getCompanies({
          ...params,
          ...(defaultParams as Partial<Company>),
          ...(restFilter as Partial<Company>),
          tenderCategory: tenderCategory,
        });
      }

      return getTenders({
        ...params,
        ...(defaultParams as Partial<Tender>),
        ...(restFilter as Partial<Tender>),
        category: tenderCategory,
      });
    },
    [defaultParams, tenderCategory]
  );

  const renderFilter = useCallback(
    (
      filterProps: RenderFilterProps<ContractorsFilterData<Tender | Company>>
    ) => {
      return (
        <ContractorsFilter
          {...filterProps}
          okedParentNumber={okedParentNumber}
        />
      );
    },
    [okedParentNumber]
  );

  return (
    <div>
      <SearchTemplate<Tender | Company, ContractorsFilterData<Tender | Company>>
        columns={tenderType === TenderTypes.REQUEST ? columns : 1}
        horizontal={tenderType === TenderTypes.OFFER}
        viewControl={false}
        renderFilter={renderFilter}
        renderItem={renderTenderItem}
        getData={getData}
        title={title}
        controls={controls}
        initialFilter={{
          tenderType: TenderTypes.REQUEST,
        }}
        emptyIcon={<IconEmpty />}
        refreshEventName="updateHomeSearch"
        emptyTitle={emptyTitle}
      />
    </div>
  );
}

export default memo(ContractorsSearch);
