import styles from './Confirm.module.scss';
import { memo, useCallback, useState } from 'react';
import { IconAlert, IconSended } from '@app/icons';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { Modal, ModalProps } from '@app/components';

interface Props extends Partial<ModalProps> {
  textSuccess?: string;
  onAccept: () => void;
  textButtonSuccess?: string;
  textButtonCancel?: string;
  skipSuccessStep?: boolean;
  subText?: string;
}

function ConfirmModal(props: Props) {
  const {
    title,
    textSuccess = '',
    onAccept,
    visible,
    onClose,
    textButtonSuccess = 'Да',
    textButtonCancel = 'Нет',
    skipSuccessStep = false,
    subText,
  } = props;
  const [messageSent, onSendMessage] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const onAcceptHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await onAccept();
      setIsPending(true);
      setTimeout(() => {
        setIsPending(false);
      }, 1500);
      onSendMessage(true);
    } catch (error) {}
  };

  const onCloseModal = useCallback(() => {
    onSendMessage(false);

    if (!!onClose) {
      onClose();
    }
  }, [onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      title={''}
      visible={visible}
      onClose={onCloseModal}
      size="medium"
      className={styles.container}
      contentClassName={styles.content}
    >
      <div className={styles.content}>
        {!skipSuccessStep && messageSent ? (
          <>
            {isPending ? (
              <span className={styles.loader}>
                <Loader />
              </span>
            ) : (
              <>
                <span>
                  <IconSended />
                </span>
                <div className={styles.text}>{textSuccess}</div>
                <div className={styles.buttonContainer}>
                  <Button text="Понятно" onClick={onClose} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <span>
              <IconAlert />
            </span>
            <div className={styles.text}>{title}</div>
            <div className={styles.subText}>{subText}</div>
            <div className={styles.buttonContainer}>
              <Button
                text={textButtonCancel}
                className={styles.button}
                color={'cyan'}
                onClick={onCloseModal}
              />
              <Button
                text={textButtonSuccess}
                className={styles.button}
                onClick={onAcceptHandler}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default memo(ConfirmModal);
