import React, { memo, useCallback, useState } from 'react';
import { Checkbox } from '@app/components';
import { Company, Tender, TenderTypes } from '@app/models';
import { RenderFilterProps } from '@app/templates';
import TendersFilter from '../TendersFilter';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export type ContractorsFilterData<T> = {
  tenderType: TenderTypes;
} & Partial<T>;

interface Props
  extends RenderFilterProps<ContractorsFilterData<Company | Tender>> {}

const StyledVehiclesFilter = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledVehiclesFilterHeader = styled.div`
  display: flex;
  align-items: center;
`;

function ContractorsFilter(props: Props) {
  const { onSubmit, pending, okedParentNumber } = props;
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );
  const location = useLocation();
  const isSuppliers = location.pathname.includes('suppliers');

  const onChangeTenderType = useCallback(
    (item: TenderTypes) => () => {
      setTenderType(item);

      onSubmit({
        tenderType: item,
      });
    },
    [onSubmit]
  );

  const onSubmitTendersFilter = useCallback(
    (tenderFilterData: Partial<Tender>) => {
      onSubmit({
        tenderType,
        ...tenderFilterData,
      });
    },
    [onSubmit, tenderType]
  );

  return (
    <StyledVehiclesFilter>
      <StyledVehiclesFilterHeader>
        <div>
          <Checkbox
            checked={tenderType === TenderTypes.REQUEST}
            variant="radio"
            label={
              isSuppliers ? 'Список материалов' : 'Список заявок на подряд'
            }
            onChange={onChangeTenderType(TenderTypes.REQUEST)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <Checkbox
            checked={tenderType === TenderTypes.OFFER}
            variant="radio"
            label={isSuppliers ? 'Список поставщиков' : 'Список подрядчиков'}
            onChange={onChangeTenderType(TenderTypes.OFFER)}
          />
        </div>
      </StyledVehiclesFilterHeader>
      <TendersFilter
        pending={pending}
        onSubmit={onSubmitTendersFilter}
        okedParentNumber={okedParentNumber}
      />
    </StyledVehiclesFilter>
  );
}

export default memo(ContractorsFilter);
