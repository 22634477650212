import React, { memo, useCallback } from 'react';
import {
  Button,
  KatoSelectTrigger,
  OkedSelectTrigger,
  Select,
  SelectOption,
  TextField,
} from '@app/components';
import { IconSearch } from '@app/icons';
import './styles.scss';
import {
  Kato,
  Oked,
  Tender,
  TenderStatus,
  TenderStatusNames,
} from '@app/models';
import { RenderFilterProps } from '@app/templates';
import { useForm } from '@app/forms';

interface FormData {
  name: string;
  kato: SelectOption<Kato> | null;
  oked: SelectOption<Oked> | null;
  status: SelectOption<TenderStatus> | null;
}

interface Props extends RenderFilterProps<Partial<Tender>> {}

function TendersFilter(props: Props) {
  const { onSubmit, pending, okedParentNumber } = props;
  const { values, onChange, setValues } = useForm<FormData>({
    name: '',
    oked: null,
    kato: null,
    status: null,
  });

  const onClickSearch = useCallback(() => {
    const filterData: Partial<Tender> = {};

    if (!!values.name) {
      filterData.name = values.name;
    }

    if (!!values.oked) {
      filterData.okedCode = values.oked.value.code;
    }

    if (!!values.kato) {
      filterData.katoCode = values.kato.value.code;
    }

    if (!!values.status) {
      filterData.status = values.status.value;
    }

    onSubmit(filterData);
  }, [onSubmit, values]);

  const onClickClear = useCallback(() => {
    setValues({
      name: '',
      oked: null,
      kato: null,
      status: null,
    });

    onSubmit({});
  }, [onSubmit, setValues]);

  return (
    <div className="tenders-filter">
      <TextField
        label="наименование"
        placeholder="Введите наименование"
        value={values.name}
        name="name"
        onChange={onChange}
      />
      <div className="tenders-filter__grid">
        <div>
          <KatoSelectTrigger
            label="регион"
            value={!!values.kato ? values.kato.value.nameRu : ''}
            placeholder="Выберите регион"
            name="kato"
            onChange={onChange}
          />
        </div>
        <div>
          <OkedSelectTrigger
            label="Категория"
            name="oked"
            value={!!values.oked ? values.oked.value.nameRu : ''}
            placeholder="Выберите категорию"
            onChange={onChange}
            parentNumber={okedParentNumber}
          />
        </div>
        <div>
          <Select<TenderStatus>
            options={[
              TenderStatus.NEW,
              TenderStatus.ACTIVE,
              TenderStatus.CLOSED,
            ].map((statusKey) => ({
              label: TenderStatusNames[statusKey as unknown as TenderStatus],
              value: statusKey as unknown as TenderStatus,
            }))}
            label="статус объявления"
            value={values.status}
            name="status"
            placeholder="Выберите статус объявления"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="tenders-filter__footer">
        <Button text="Сбросить все" onClick={onClickClear} variant="text" />
        <Button
          loading={pending}
          icon={IconSearch}
          text="Найти"
          onClick={onClickSearch}
        />
      </div>
    </div>
  );
}

export default memo(TendersFilter);
