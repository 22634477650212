import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Chat, ChatMessage } from '@app/models';
import styled from 'styled-components';
import { Loader, Paper } from '@app/components';
import { IconSendMessage } from '@app/icons';
import { MessageItem } from './components';
import TextField from '../../components/TextField/TextField';
import { useForm } from '@app/forms';
import { createMessage, getChatEventSource } from '@app/api';
import { useUserContext } from '@app/providers';
import { dateFormat } from '@app/helpers';

interface Props {
  chatId: string | null;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 500px;
`;

const StyledMessagesList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  list-style: none;
  padding: 0 10px 0 0;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    /* padding: 10px; */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a57e8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3a57e8;
  }
`;

const StyledFooter = styled.div`
  margin-top: auto;
  //margin-bottom: 5px;
`;

const StyledDateContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;
const StyledDate = styled.div`
  background: #f5f5f5;
  border-radius: 15px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

interface MessageData {
  message: string;
}

function UsersChat(props: Props) {
  const { chatId } = props;
  const { values, onChange, setValues } = useForm<MessageData>({
    message: '',
  });
  const { user, currentProfile } = useUserContext();

  const [chat, setChat] = useState<Chat>({} as Chat);
  const [pending, setPending] = useState<boolean>(true);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const eventSourceRef = useRef<EventSource | null>(null); // Ссылка на объект EventSource

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      if (!chatId) return;
      const eventSource = await getChatEventSource(chatId);
      eventSource.onerror = (err) => {};
      eventSource.onmessage = ({ data }) => {
        setChat(JSON.parse(data));
      };
      eventSourceRef.current = eventSource;
    } catch (e) {
    } finally {
      setPending(false);
    }
  }, [chatId]);

  useEffect(() => {
    getData();
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const onSendMessage = useCallback(async () => {
    try {
      if (!chatId) return;
      const params: Partial<ChatMessage> = {
        message: values.message,
        chatId: chatId,
        authorUserId: user.id,
      };

      if (currentProfile.currentType === 'company') {
        params.authorCompanyId = currentProfile.id;
      }

      await createMessage(params);
      setValues({ message: '' });
    } catch (e) {}
  }, [
    chatId,
    values.message,
    user.id,
    currentProfile.currentType,
    currentProfile.id,
    setValues,
  ]);

  return (
    <Paper title="Чат">
      <StyledContent>
        <StyledMessagesList ref={chatContainerRef}>
          {pending ? (
            <Loader />
          ) : (
            chat.groupedMessage?.map((messages) => {
              return (
                <>
                  {messages.messages?.map((message) => {
                    return (
                      <MessageItem
                        key={message.id}
                        message={message}
                        user={user}
                      />
                    );
                  })}
                  <StyledDateContainer>
                    <StyledDate>{dateFormat(messages.date)}</StyledDate>
                  </StyledDateContainer>
                </>
              );
            })
          )}
        </StyledMessagesList>
        <StyledFooter>
          <TextField
            label="Написать"
            onChange={onChange}
            name="message"
            placeholder="Введите сообщение"
            value={values.message}
            endIcons={[<IconSendMessage onClick={onSendMessage} />]}
            onSendMessage={onSendMessage}
          />
        </StyledFooter>
      </StyledContent>
    </Paper>
  );
}

export default memo(UsersChat);
