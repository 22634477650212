import React, {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Vehicle } from '@app/models';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FilePicker, Loader, Paper, Tabs } from '@app/components';
import {
  deleteVehicleFile,
  getVehicleById,
  updateVehicle,
  uploadVehicleFiles,
} from '@app/api';
import styled from 'styled-components';
import { IconCheckCircle16 } from '@app/icons';
import { ProfileAvatar } from '../../components';
import { useForm, VehicleCreateForm } from '@app/forms';
import { LayoutProfile } from '@app/layouts';
import placeholderImage from '../../../Vehicle/placeholder.png';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@app/providers';
import {
  VehicleFormData,
  VehicleFormValues,
} from '../../../../forms/VehicleCraete/VehicleCreate';

interface Props {}

interface VehicleData extends VehicleFormValues {
  filesIds: string | null;
  companyId: string | null;
}

const StyledEdit = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledLoaderCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }
`;

function PageProfileVehicleEdit(props: Props) {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { onChange, values, errors, validate, setValues } =
    useForm<VehicleData>({
      name: '',
      description: '',
      price: '',
      dateOfIssue: '',
      files: [],
      kato: null,
      type: '',
      brand: '',
      companyId: '',
      filesIds: '',
      measure: null,
    });

  const getData = useCallback(async () => {
    try {
      const response = await getVehicleById(id as string);

      setVehicle(response);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onChangeVehicleForm = useCallback(
    (values: Partial<VehicleFormValues>) => {
      const toUpdateData: Partial<VehicleFormValues> = {
        name: values.name,
        description: values.description,
        price: values.price,
        dateOfIssue: values.dateOfIssue,
        type: values.type,
        brand: values.brand,
        measure: values.measure,
      };

      if (values.kato) {
        toUpdateData.kato = values.kato;
      }

      setValues(toUpdateData);
    },
    [setValues]
  );

  const onChangeAvatar = useCallback(
    async (files: File) => {
      try {
        if (!vehicle) return;
        const response = await uploadVehicleFiles(
          vehicle!.id,
          [files],
          'imagesIds'
        );
        setVehicle({ ...vehicle, imagesIds: response?.imagesIds });
      } catch (e) {}
    },
    [vehicle]
  );

  const onClickSave = useCallback(async () => {
    try {
      const hasErrors = await validate(
        yup.object().shape({
          name: yup.string().required(t('required', { ns: 'validation' })!),
          description: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          brand: yup.string().required(t('required', { ns: 'validation' })!),
          type: yup.string().required(t('required', { ns: 'validation' })!),
          price: yup.string().required(t('required', { ns: 'validation' })!),
          kato: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          dateOfIssue: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          measure: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      setPending(true);

      const toUpdateData: Partial<VehicleFormData> = {
        description: values.description,
        price: values.price,
        name: values.name,
        dateOfIssue: values.dateOfIssue,
        type: values.type,
        brand: values.brand,
      };

      await updateVehicle(vehicle!.id, {
        ...toUpdateData,
        katoCode: values.kato?.code,
        companyId: vehicle?.companyId,
        measure: values.measure && values.measure.value,
      });

      showNotification({
        variant: 'success',
        message: 'Данные объявления успешно обновлены',
      });

      setPending(false);

      navigate(`/profile/vehicles/${vehicle!.id}`);
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Не удалось обновить данные объявления',
      });

      setPending(false);
    }
  }, [validate, t, values, showNotification, navigate, vehicle]);

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        if (!vehicle) return null;

        const response = await uploadVehicleFiles(
          vehicle!.id,
          files,
          'filesIds'
        );
        setVehicle({ ...vehicle, filesIds: response.filesIds });
      } catch (e) {}
    },
    [vehicle]
  );

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        if (!vehicle) return false;

        const files = vehicle
          .filesIds!.split(',')
          .filter((item) => item !== fileId)
          .join(',');

        setVehicle({ ...vehicle, filesIds: files });

        await deleteVehicleFile(vehicle.id, fileId);

        return true;
      } catch (e) {
        return false;
      }
    },
    [vehicle]
  );

  const vehicleImages = useMemo(() => {
    if (!vehicle?.imagesIds) {
      return '';
    }

    return vehicle?.imagesIds;
  }, [vehicle?.imagesIds]);

  const leftContent = useMemo(
    () => (
      <ProfileAvatar
        avatarFiles={vehicleImages}
        editable={true}
        onSubmit={onChangeAvatar}
        customPlaceholder={placeholderImage}
        size={400}
        borderRadius={15}
      />
    ),
    [onChangeAvatar, vehicleImages]
  );

  const rightContent = useMemo(() => {
    if (!vehicle) {
      return null;
    }
    return (
      <VehicleCreateForm
        vehicle={vehicle}
        defaultExpanded={true}
        onSubmit={onChange}
        edit={true}
        setValues={onChangeVehicleForm}
        errors={errors}
      />
    );
  }, [vehicle, onChange, onChangeVehicleForm, errors]);

  const tabLabels = useMemo(() => {
    const returnTabLabels = ['Документы и Файлы', 'Отклики'];

    return returnTabLabels;
  }, []);

  const tabContent = useMemo<ReactNode[]>(() => {
    if (!vehicle) {
      return [];
    }

    const content = [];

    content.push(
      <FilePicker
        loadableFiles={!!vehicle.filesIds ? vehicle.filesIds.split(',') : []}
        onChange={onChangeFiles}
        editable={true}
        onDelete={onDeleteFile}
      />
    );

    return [...content];
  }, [onChangeFiles, onDeleteFile, vehicle]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return (
      <StyledLoaderCenter>
        <Loader />
      </StyledLoaderCenter>
    );
  }

  if (!vehicle || error) {
    return null;
  }

  return (
    <StyledEdit>
      <LayoutProfile
        pending={pending}
        toolbarProps={{
          title: 'Данные объявления',
        }}
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <StyledEdit>
            <Tabs labels={tabLabels}>
              {tabContent.map((elem, index) => {
                return <Fragment key={index}>{elem}</Fragment>;
              })}
            </Tabs>
          </StyledEdit>
        </Paper>
        <StyledControls>
          <Button text="Отмена" color={'default'} onClick={onClickCancel} />
          <Button
            text="Сохранить"
            icon={IconCheckCircle16}
            onClick={onClickSave}
            loading={pending}
            disabled={pending}
          />
        </StyledControls>
      </LayoutProfile>
    </StyledEdit>
  );
}

export default memo(PageProfileVehicleEdit);
