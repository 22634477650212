import React, {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Tender } from '@app/models';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  FilePicker,
  Grid,
  Loader,
  Paper,
  Tabs,
  TextField,
} from '@app/components';
import {
  deleteTenderFile,
  getTenderById,
  updateTender,
  uploadTenderFiles,
} from '@app/api';
import styled from 'styled-components';
import { IconCheckCircle16, IconClear, IconSendMessage } from '@app/icons';
import { ProfileAvatar } from '../../components';
import { TenderForm, TenderFormData, useForm } from '@app/forms';
import { LayoutProfile } from '@app/layouts';
import placeholderImage from '../../../Tender/placeholder.png';
import { Replies } from '../../../../common/TenderInfo/components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@app/providers';
import { TenderFormValues } from '../../../../forms/Tender/Tender';

interface Props {}

interface TenderData extends TenderFormValues {
  filesIds: string | null;
  companyId: string | null;
  requirements?: string[];
}

const StyledEdit = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledLoaderCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RequirementsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-top: -16px;
`;

const RequirementStyled = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-left: 16px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0a0a0a;
    max-width: 500px;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: min-content;
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }
`;

function PageProfileTenderEdit(props: Props) {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [tender, setTender] = useState<Tender | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [requirement, setRequirement] = useState<string>('');
  const [requirements, setRequirements] = useState<string[]>([]);
  const { onChange, values, errors, validate, setValues } = useForm<TenderData>(
    {
      oked: null,
      kato: null,
      name: '',
      description: '',
      deliveryDate: null,
      endDate: null,
      price: '',
      companyId: null,
      requirements: [''],
      filesIds: null,
      measure: null,
    }
  );

  const getData = useCallback(async () => {
    try {
      const tenderResponse = await getTenderById(id as string);

      setTender(tenderResponse);
      setError(false);
      setPending(false);
      if (tenderResponse.requirements) {
        const requirements = tenderResponse.requirements.filter(
          (el) => el.name.length > 1
        );
        setRequirements(requirements.map((elem) => elem.name));
      }
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const onChangeRequirement = useCallback((value: string) => {
    setRequirement(value);
  }, []);

  const onClickAddRequirement = useCallback(() => {
    setRequirements((prevRequirements) => [...prevRequirements, requirement]);
    setRequirement('');
  }, [requirement]);

  const onClickDeleteRequirement = useCallback(
    (index: number) => () => {
      setRequirements((prevRequirements) =>
        prevRequirements.filter(
          (prevRequirement, prevRequirementIndex) =>
            prevRequirementIndex !== index
        )
      );
    },
    []
  );

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onChangeTenderForm = useCallback(
    (values: Partial<TenderFormValues>) => {
      const toUpdateData: Partial<TenderFormValues> = {
        kato: values?.kato,
        description: values.description,
        price: values.price,
        deliveryDate: values.deliveryDate,
        endDate: values.endDate,
        name: values.name,
        measure: values.measure,
      };

      if (!!values.oked) {
        toUpdateData.oked = values.oked;
      }

      setValues(toUpdateData);
    },
    [setValues]
  );

  const onChangeAvatar = useCallback(
    async (files: File) => {
      try {
        if (!tender) return;
        const response = await uploadTenderFiles(
          tender!.id,
          [files],
          'imagesIds'
        );
        setTender({ ...tender, imagesIds: response?.imagesIds });
      } catch (e) {}
    },
    [tender]
  );

  const onClickSave = useCallback(async () => {
    try {
      const hasErrors = await validate(
        yup.object().shape({
          kato: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          oked: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          name: yup.string().required(t('required', { ns: 'validation' })!),
          description: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          endDate: yup.date().required(t('required', { ns: 'validation' })!),
          deliveryDate: yup
            .date()
            .required(t('required', { ns: 'validation' })!),
          price: yup.string().required(t('required', { ns: 'validation' })!),
          measure: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      setPending(true);

      const toUpdateData: Partial<TenderFormData> = {
        description: values.description,
        price: values.price,
        name: values.name,
        companyId: tender?.companyId,
      };

      if (values?.measure) {
        toUpdateData.measure = values.measure.value;
      }

      await updateTender(tender!.id, {
        ...toUpdateData,
        requirements: values.requirements!.join(','),
        deliveryDate: values.deliveryDate!.toString(),
        endDate: values.endDate!.toString(),
        katoCode: values.kato?.code,
        okedCode: values.oked?.code,
      });

      showNotification({
        variant: 'success',
        message: 'Данные объявления успешно обновлены',
      });

      setPending(false);

      navigate(`/profile/tender/${tender!.id}`);
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Не удалось обновить данные объявления',
      });

      setPending(false);
    }
  }, [validate, t, values, showNotification, navigate, tender]);

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        if (!tender) return null;

        const response = await uploadTenderFiles(tender!.id, files, 'filesIds');
        setTender({ ...tender, filesIds: response.filesIds });
      } catch (e) {}
    },
    [tender]
  );

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        if (!tender) return false;

        const files = tender
          .filesIds!.split(',')
          .filter((item) => item !== fileId)
          .join(',');

        setTender({ ...tender, filesIds: files });

        await deleteTenderFile(tender.id, fileId);

        return true;
      } catch (e) {
        return false;
      }
    },
    [tender]
  );

  const tenderImages = useMemo(() => {
    if (!tender?.imagesIds) {
      return '';
    }

    return tender?.imagesIds;
  }, [tender?.imagesIds]);

  const leftContent = useMemo(
    () => (
      /* <LoadableImage imageIds={tenderImages} placeholder={placeholderImage} />*/
      <ProfileAvatar
        avatarFiles={tenderImages}
        editable={true}
        onSubmit={onChangeAvatar}
        customPlaceholder={placeholderImage}
        size={400}
        borderRadius={15}
      />
    ),
    [onChangeAvatar, tenderImages]
  );

  const rightContent = useMemo(() => {
    if (!tender) {
      return null;
    }

    return (
      <TenderForm
        tender={tender}
        defaultExpanded={true}
        tenderCategory={tender.category}
        onSubmit={onChange}
        edit={true}
        setValues={onChangeTenderForm}
        errors={errors}
      />
    );
  }, [tender, onChange, onChangeTenderForm, errors]);

  const tabLabels = useMemo(() => {
    const returnTabLabels = ['Общая информация'];
    returnTabLabels.push('Документы и Файлы');

    returnTabLabels.push('Отклики');

    return returnTabLabels;
  }, []);

  const tabContent = useMemo<ReactNode[]>(() => {
    if (!tender) {
      return [];
    }

    const content = [
      <StyledInfo>
        <Grid>
          <TextField
            value={requirement}
            onChange={onChangeRequirement}
            label="требование"
            placeholder="Введите наименование требования. Например, техосмотр не позднее октября"
            error={!!errors.description}
            helperText={errors.description}
            endIcons={[<IconSendMessage onClick={onClickAddRequirement} />]}
            onSendMessage={onClickAddRequirement}
          />
          <RequirementsGrid>
            {requirements.map((requirement, requirementIndex) => (
              <RequirementStyled key={requirementIndex.toString()}>
                <p>{requirement}</p>
                <IconClear
                  onClick={onClickDeleteRequirement(requirementIndex)}
                  className={'form-tender__clearBtn'}
                />
              </RequirementStyled>
            ))}
          </RequirementsGrid>
        </Grid>
        {/*<StyledFooter>
          {actions.map((action, actionIndex) => (
            <Fragment key={actionIndex}>{action}</Fragment>
          ))}
        </StyledFooter>*/}
      </StyledInfo>,
    ];

    content.push(
      <FilePicker
        loadableFiles={!!tender.filesIds ? tender.filesIds.split(',') : []}
        onChange={onChangeFiles}
        editable={true}
        onDelete={onDeleteFile}
      />
    );
    content.push(<Replies replies={tender.replies} />);

    return [...content];
  }, [
    errors.description,
    onChangeFiles,
    onChangeRequirement,
    onClickAddRequirement,
    onClickDeleteRequirement,
    onDeleteFile,
    requirement,
    requirements,
    tender,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return (
      <StyledLoaderCenter>
        <Loader />
      </StyledLoaderCenter>
    );
  }

  if (!tender || error) {
    return null;
  }

  return (
    <StyledEdit>
      <LayoutProfile
        pending={pending}
        toolbarProps={{
          title: 'Данные объявления',
        }}
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <StyledEdit>
            <Tabs labels={tabLabels}>
              {tabContent.map((elem, index) => {
                return <Fragment key={index}>{elem}</Fragment>;
              })}
            </Tabs>
          </StyledEdit>
        </Paper>
        {/*   <TenderInfo
          tender={tender}
          viewReplies={true}
          actions={actionButtons}
          editable={true}
        />
        <ModalConfirm
          textSuccess={'Успешно удален!'}
          onAccept={onClickDelete}
          title={'Вы уверены, что хотите удалить?'}
          visible={deleteModalVisible}
          onClose={onCloseDeleteModal}
        />*/}
        <StyledControls>
          <Button text="Отмена" color={'default'} onClick={onClickCancel} />
          <Button
            text="Сохранить"
            icon={IconCheckCircle16}
            onClick={onClickSave}
            loading={pending}
            disabled={pending}
          />
        </StyledControls>
      </LayoutProfile>
    </StyledEdit>
  );
}

export default memo(PageProfileTenderEdit);
