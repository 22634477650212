import React, { memo, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalProps,
  OkedSelect,
  SelectOption,
} from '@app/components';
import { Oked } from '@app/models';
import './styles.scss';
import { IconCloseArrow } from '@app/icons';

interface OkedModalProps extends Partial<ModalProps> {
  onChange: (value: SelectOption<Oked>) => void;
  onClose?: () => void;
  parentNumber?: number;
}

function OkedModal(props: OkedModalProps) {
  const { visible, className = '', onChange, onClose, parentNumber } = props;

  const onClickClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  if (!visible) {
    return null;
  }

  return (
    <Modal className={`m-oked ${className}`.trim()} visible={visible}>
      <div className="m-oked__header">
        Выберите сферу деятельности{' '}
        <div className="m-oked__close-btn" onClick={onClickClose}>
          <IconCloseArrow />
        </div>
      </div>
      {visible && (
        <OkedSelect onChange={onChange} parentNumber={parentNumber} />
      )}
      <div className="m-oked__footer">
        <Button text="Выбрать" onClick={onClickClose} />
      </div>
    </Modal>
  );
}

export default memo(OkedModal);
