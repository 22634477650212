import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Doc, DocStatus, DocStatusName, UserType } from '@app/models';
import { Button, Grid, Paper } from '@app/components';
import { DownloadButton, Signer } from '@app/common';
import { useNotification, useUserContext } from '@app/providers';
// @ts-ignore
import { NCALayerClient } from 'ncalayer-js-client';
import { getFileById, updateDoc } from '@app/api';
import { blobToBase64 } from '@app/helpers';

interface Props {
  contract: Doc;
  updateContract: (contract: Partial<Doc>) => void;
  checkIin?: () => void;
}

function ContractSigners(props: Props) {
  const { contract, updateContract, checkIin } = props;
  const { user, company } = useUserContext();
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);

  const ncaLayerClient = useMemo(() => new NCALayerClient(), []);

  const ownerSigned = useMemo(
    () =>
      [DocStatus.OWNER_SIGNED, DocStatus.SIGNED].indexOf(contract.status) > -1,
    [contract]
  );

  const recipientSigned = useMemo(
    () =>
      [DocStatus.RECIPIENT_SIGNED, DocStatus.SIGNED].indexOf(contract.status) >
      -1,
    [contract]
  );

  const fileId = useMemo<string | null>(() => {
    try {
      return contract?.fileId as string;
    } catch (e) {
      return null;
    }
  }, [contract]);

  const onClickSign = useCallback(async () => {
    try {
      if (checkIin && (!contract?.owner?.iin || !contract?.recipient?.iin)) {
        checkIin();
        return;
      }
      if (!ncaLayerClient.wsConnection) {
        await ncaLayerClient.connect();
      }
    } catch (error) {
      showNotification({
        variant: 'error',
        message: 'NCALayer не подключен! Подключите и попробуйте снова',
      });

      return;
    }

    try {
      if (ncaLayerClient.wsConnection.readyState !== 1) {
        ncaLayerClient.wsConnection.close();

        showNotification({
          variant: 'error',
          message: 'NCALayer не подключен! Подключите и попробуйте снова',
        });

        return;
      }

      setPending(true);

      const blobContractFile = await getFileById(contract.fileId);
      const base64ContractFile = await blobToBase64(blobContractFile);

      const signResponse = await ncaLayerClient.createCAdESFromBase64(
        NCALayerClient.fileStorageType,
        base64ContractFile,
        'SIGNATURE',
        false
      );

      const docData: Partial<Doc> = {
        id: contract.id,
      };

      if (user.type === UserType.INDIVIDUAL) {
        if (user.id === contract.recipientId) {
          docData.recipientSign = signResponse;
          docData.status = DocStatus.RECIPIENT_SIGNED;
        } else {
          docData.ownerSign = signResponse;
          docData.status = DocStatus.OWNER_SIGNED;
        }
      } else {
        if (company!.id === contract.recipientCompanyId) {
          docData.recipientSign = signResponse;
          docData.status = DocStatus.RECIPIENT_SIGNED;
        } else {
          docData.ownerSign = signResponse;
          docData.status = DocStatus.OWNER_SIGNED;
        }
      }

      const docResponse = await updateDoc(docData);

      if (!!updateContract) {
        updateContract({
          status: docResponse.status,
        });
      }

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [
    checkIin,
    ncaLayerClient,
    showNotification,
    contract,
    user,
    updateContract,
    company,
  ]);

  const controls = useMemo(() => {
    if (contract.status === DocStatus.SIGNED) {
      return [
        <DownloadButton
          text="Скачать договор"
          fileId={fileId}
          variant="outlined"
        />,
      ];
    }

    if (user.type === UserType.INDIVIDUAL) {
      if (user.id === contract.recipientId && recipientSigned) {
        return [];
      }

      if (user.id === contract.ownerId && ownerSigned) {
        return [];
      }
    } else {
      if (company!.id === contract.recipientCompanyId && recipientSigned) {
        return [];
      }

      if (company!.id === contract.companyId && ownerSigned) {
        return [];
      }
    }

    return [
      <Button
        text="Подписать"
        onClick={onClickSign}
        disabled={pending}
        loading={pending}
      />,
    ];
  }, [
    user,
    recipientSigned,
    ownerSigned,
    onClickSign,
    pending,
    contract,
    company,
    fileId,
  ]);

  useEffect(() => {
    return () => {
      if (ncaLayerClient.wsConnection) {
        ncaLayerClient.wsConnection.close();
      }
    };
  }, [ncaLayerClient]);

  return (
    <Paper title="Подписание сторон" controls={controls}>
      <Grid columns={2} gap={48}>
        <Signer
          signedText={DocStatusName[DocStatus.OWNER_SIGNED]}
          signed={ownerSigned}
          user={!contract.companyId ? contract.owner : null}
          company={!!contract.companyId ? contract.company : null}
        />
        <Signer
          signedText={DocStatusName[DocStatus.RECIPIENT_SIGNED]}
          signed={recipientSigned}
          user={!contract.recipientCompanyId ? contract.recipient : null}
          company={
            !!contract.recipientCompanyId ? contract.recipientCompany : null
          }
        />
      </Grid>
    </Paper>
  );
}

export default memo(ContractSigners);
